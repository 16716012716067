<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="선택해주세요" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>

		<h2> 사용자 충전 프로토콜</h2>

		<p>본 충전 서비스 계약(이하 "본 계약")은 귀하가 PIX TECHNOLOGY PTE. LTD의 UniLive 플랫폼과 체결한 것입니다.플랫폼은 본 계약의 약정과 수시로 발표되는 운영 규칙에 따라 귀하께
			서비스를 제공할 것입니다(이하 "사탕 서비스" 또는 "본 서비스"). </p>

		<p>사용자에게 더 나은 서비스를 제공하기 위해 서비스 사용자(즉, "사탕"을 구매한 계정 사용자를 주문하여 "귀하"라고 함)는 본 서비스를 사용하기 전에 본 협의를 진지하게 읽고 충분히 이해하십시오. 그
			중에서 플랫폼의 책임을 면제하거나 제한하는 조항은 귀하가 중점적으로 읽어주십시오.</p>

		<p>귀하가 본 협의에 동의하지 않을 경우 귀하는 어떠한 형식으로든 다음 절차를 진행하지 마십시오(충전 등 조작 단추를 누르거나 지불하는 것을 포함하되 이에 국한되지 않음). 그리고 본 서비스를 사용하지
			마십시오.</p>

		<p>

			플랫폼은 미성년자가 사탕 서비스를 사용하는 것을 금지한다.플랫폼은 이 선의로 귀하가 미성년자의 보호자라면 귀하가 감호하는 미성년자에게 감호 직책을 부담해야 합니다. 귀하는 미성년자가 본 플랫폼의 관련 제품
			및 서비스를 사용할 때 청소년 모델 및/또는 기타 미성년자 보호 도구를 열어 미성년자가 관련 제품 및 서비스를 정확하게 사용하도록 감독, 지도하는 동시에 인터넷 지불 방식에 대한 제한과 관리를 강화하여
			미성년자가 건강하게 성장하는 좋은 환경을 공동으로 조성하십시오.

		</p>

		<h3> 1. 서비스 내용 </h3>

		<p>

			1."사탕"은 플랫폼이 귀하에게 제공하는 플랫폼 내에서만 관련 소비를 하는 가상 도구입니다.귀하가"사탕"을 구매한 후, 플랫폼 관련 페이지의 설명 및 안내에 따라 플랫폼 상품 또는 서비스를 사용할 수
			있으며, 생방송 포상 서비스 (사용자는 앵커에게 가상 선물을 증정하는 방식으로 앵커 공연 등 서비스에 대가를 지불할 수 있음) 등을 포함하되 이에 국한되지 않습니다.의심을 피하기 위해 성두서비스는 사용자가
			비용을 지불하고 플랫폼이 사용자에게 지속적으로 제공하는 전반 인터넷기술 및 관련 서비스로서 성두서비스는 인터넷지불서비스가 아니며"성두"도 토큰표권, 가상화페 또는 선불금증빙이 아니며 화페가치, 선불가치치를
			구비하지 못한다.

		</p>

		<p>2、귀하가"사탕"을 구매한 후 본 협의에 규정된 목적으로만 사용할 수 있으며, 회사가 제공하는 제품/서비스의 범위를 초과하여 사용할 수 없으며, 다른 UniLive 계정 간에 이전할 수 없습니다.</p>

		<p>

			3. 귀하는 플랫폼을 통해 공식 채널을 지정하여"사탕"을 구매해야 합니다. 플랫폼은 회사의 권한을 받지 않은 어떠한 채널도 인정하지 않습니다.만약 당신이 비공식 경로를 사용하여"사탕"을 구매한다면,
			플랫폼은 해당"사탕"이 순조롭게 당신의 계정에 진입할 수 있다는 것을 보장할 수 없으며, 또한 해당 행위는 사기, 돈세탁 등 위험을 수반하여 당신, 플랫폼 및 관련 제3자에게 돌이킬 수 없는 손실이나
			손해를 초래할 수 있습니다.따라서 비공식 채널 구매 행위는 위반으로 간주되며, 이때 플랫폼은 귀하의 계정 내의"사탕"을 공제하거나 비울 권리가 있으며, 귀하의 계정의 전부 또는 일부 기능을 제한하고,
			귀하의 계정을 영구적으로 봉쇄할 때까지 귀하는 이로 인해 초래된 귀하의 손실을 스스로 부담해야 한다;귀하가 상술한 약정을 위반하여 플랫폼 또는 기타 제3자에게 손해를 입힌 경우 귀하는 배상을 책임져야
			합니다.만약 당신이 플랫폼을 통해 티몰 등 제3자 플랫폼에 개설한 공식 점포 등 경로를 통해 구매한 경우, 플랫폼은 당신이 지정한"사탕"을 통해 계정의 사용자정보 및/또는 귀하와 회사의 별도 협의 (있을
			경우) 중의 약정으로 서비스 사용자를 인정할 것입니다.

		</p>

		<p>

			4. 귀하가 "사탕"을 구매하는 비용은 회사 또는 회사가 지정한 합작측이 수취합니다.플랫폼은 특히 다른 구매 채널의 관련 서비스 업체가 자신의 운영 전략에 따라 사용자가 비용을 지불할 때 채널 서비스
			비용을 받을 수 있으며, 이는 사용자가 다른 채널을 통해 같은 수량의"사탕"을 구매하는 비용 금액 또는 같은 금액의 비용을 지불하여 구매한"사탕"의 수량에 차이가 있을 수 있으며, 구체적으로
			당신이"사탕"을 구매할 때의 페이지 전시를 기준으로 한다.관련 페이지 정보를 확인하고 "사탕" 구매 경로를 합리적으로 선택하십시오.

		</p>

		<p>5. 만약에 귀하가 타인의 계정을 위해"사탕"을 구매하고자 한다면 플랫폼은 상응하는 수량의"사탕"을 귀하가 지정한 계정 항목에 발급할 것입니다. 이 계정 사용자는 본 협의에 따라 플랫폼이 제공하는 사탕
			서비스를 사용하기로 약정할 수 있습니다.만약 이로 인해 분쟁이 발생하면 귀하와 이 계정 사용자는 스스로 협상하여 해결하며, 플랫폼은 이에 대해 귀하 및 이 계정 사용자에게 책임을 질 필요가 없습니다.

		</p>



		<h3> 2. 이성적인 소비</h3>

		<p>1. 플랫폼은 이성적인 소비를 제창하고 수입에 따라 지출한다.반드시 자신의 소비 능력과 실제 수요에 근거하여 합리적으로 사탕 서비스를 구매하고 사용하여 과소비를 피하십시오. </p>

		<p>

			2. 귀하가"사탕"을 구매하는데 사용한 자금은 귀하가 합법적으로 획득하고 사용할 권리가 있는 수입이어야 합니다.만약에 귀하가 본 조항을 위반하여 발생한 분쟁이나 분쟁은 귀하가 스스로 해결하고 법적 결과를
			부담해야 합니다. 만약에 귀하의 행위가 플랫폼이나 제3자에게 손해를 입히면 귀하는 전액 배상해야 합니다.만약에 플랫폼이 (주동적으로 발견하거나 제3자의 고소 또는 주관부서, 사법기관의 통지를 받는 등
			상황을 포함하되 이에 국한되지 않음) 귀하가 상술한 약정을 위반한 혐의가 있음을 발견하면 플랫폼은 귀하의 계정내의"사탕"을 공제하거나 비우고 귀하의 계정의 전부 또는 일부 기능을 제한하며 영구적으로 귀하의
			계정을 봉쇄할 권리가 있습니다.이와 동시에 플랫폼은 관련 정보를 보존하고 관련 주관부문, 사법기관에 보고할 권리가 있다.

		</p>

		<p>

			3. 플랫폼은 사용자의 비이성적인 포상을 유도, 자극, 선동 및 미성년자를 유인, 교사하여 허위신분정보로 포상하는 행위를 엄격히 배격한다.만약 당신이 앞에서 서술한 규정 위반 행위를 발견한다면, 당신은
			플랫폼 공시 채널 (생방송실 오른쪽 아래-신고, UniLive 생방송 업계 자율 플랫폼-신고 등) 에 따라 플랫폼에 신고할 수 있으며, 플랫폼은 법과 규정에 따라 처리 조치를 취할 것이다.저희와 함께
			건강하고 질서 있는 지역사회 생태를 구축하기를 기대합니다.

		</p>


		<h3> 3. 귀하의 권리와 의무</h3>

		<p>1. 귀하가 본 서비스를 사용하는 과정에서 제공한 개인 정보나 자료는 진실하고 정확해야 하며 법률 규정과 플랫폼의 요구에 따라 감독 기구의 조사에 협조하고 협조해야 합니다(필요한 경우). </p>

		<p>

			2、귀하가"사탕"을 구매할 때 신중하게 선택 및/또는 귀하의 계정 정보/계정 바인딩 메일박스,"사탕"구매 수량 등을 입력해야 합니다.만약 귀하의 입력 오류, 조작 부당, 비용 계산 방식을 충분히 이해하지
			못한 등 요소로 인해 계정 오류,"사탕"수량 오류 등 구매 오류 상황이 발생하여 귀하의 손실 또는 지출을 초래한 경우, 플랫폼은 보상 또는 배상을 하지 않을 권리가 있습니다.

		</p>

		<p>3、귀하는 귀하의 계정을 잘 보관하고 사용하며 해당 계정의 모든 조작 행위와 결과에 대해 책임을 져야 합니다.귀하가 다음과 같은 상황에서 플랫폼이 본 서비스를 제공하지 못하거나 본 서비스를 제공할 때
			오류가 발생하면 이로 인해 귀하의 손실이 발생할 수 있습니다. 법률과 법규의 명확한 요구 사항을 제외하고 플랫폼은 법적 책임을 지지 않습니다. </p>

		<p>(1) 귀하의 계정이 효력을 상실, 분실, 도용, 차단됨; </p>

		<p>(2) 귀하의 계좌에 연결된 제3자 지불기구, 은행 계좌가 동결, 차압 또는 기타 이상이 발생하거나 귀하가 인증되지 않은 계좌 또는 본인이 아닌 계좌를 사용했습니다. </p>

		<p>(3) 귀하가 다른 사람에게 계정 비밀번호를 알려주거나 다른 방식으로 다른 사람이 귀하의 계정을 로그인하여 사용할 수 있도록 허락했습니다. </p>

		<p>(4) 귀하에게 고의나 중대한 과실이 있는 기타 상황. </p>

		<p>

			4. 귀하는 본 서비스를 합법적이고 합법적으로 사용해야 하며 본 서비스를 어떠한 위법 범죄, 공서양속, 사회 도덕 기풍에 위배되거나 플랫폼의 정상적인 운영을 방해하거나 제3자의 합법적 권익을 침해하는
			목적으로 사용해서는 안 되며 귀하가 본 서비스를 사용하는 행위도 귀하에게 구속력이 있는 문서나 기타 요구(있을 경우)를 위반해서는 안 됩니다.플랫폼은 특히 귀하에게 외부에서 빌리거나 양도하거나 기타
			방식으로 귀하의 계정을 다른 사람에게 제공하여 사용하지 말고 타인이 귀하의 계정을 통해 앞에서 서술한 약정을 위반하는 행위를 실시하여 자신의 계정과 재산의 안전을 보호하는 것을 합리적으로 방비하라고 주의를
			줍니다.

		</p>

		<p>

			5. 플랫폼은 법에 따라 미성년자 소비 환불 서비스를 제공하여 미성년자 및 그 후견인의 합법적 권익을 보장한다;귀하는 불법적인 목적이나 부당한 방식으로 이 서비스를 사용해서는 안 됩니다. 여기에는 성인이
			미성년자를 사칭하여 환불을 사취하거나 미성년자의 소비를 유도한 후 환불을 신청하는 등이 포함되지만 이에 국한되지는 않습니다.앞에서 서술한 행위는 본 협의에 대한 심각한 위반을 구성할 것이며, 플랫폼은
			합리적인 확인을 거친 후 환불을 거부할 권리가 있으며, 법에 따라 귀하의 법적 책임을 추궁할 권리를 보유합니다.

		</p>

		<p>

			6. 귀하는 이 전속계정을 이용하여 돈세탁 등 어떠한 불법활동도 하지 않을 것을 약속해야 하며, 타인이 귀하가 이 전속계정을 통해 돈세탁 등을 완성하는 어떠한 불법요구도 받아들일 수 없습니다.귀하가 상술한
			약정을 위반할 경우 플랫폼은 돈세탁방지 관련 법률 규정 및/또는 주관부서, 사법기관의 요구에 따라 서비스 제공을 잠시 중단하고 조사를 전개하는 등 조치를 취할 권리가 있으며 이로 인해 귀하가 입은 어떠한
			손실이나 손해도 부담할 필요가 없습니다.

		</p>

		<p>

			7. 귀하가 본 서비스를 사용하는 과정에서 제3자가 제공한 관련 서비스(예를 들어 지불 서비스 등)와 관련될 경우 귀하는 본 계약의 규정을 준수하는 것 외에 해당 제3자의 협의와 관련 규칙에 동의하고
			준수해야 합니다. 어떠한 경우에도 해당 제3자와 그 제공과 관련된 서비스에 대해 발생하는 분쟁은 귀하가 해당 제3자와 해결하고 플랫폼은 귀하 또는 해당 제3자에게 어떠한 책임도 지지 않습니다.

		</p>



		<h3> 4. 플랫폼 권리와 의무</h3>

		<p>

			1. 플랫폼은 법률, 법규 개정, 감독관리 부서의 요구, 거래 안전 보장, 운영 전략 갱신, 시장 환경 변화 등 방면의 고려에 근거하여 때때로"사탕"서비스에 관련 제한, 알림 등을 설정할 권리가 있으며,
			전체 또는 일부 사용자의 거래 한도액 및/또는 거래 횟수 제한, 특정 사용자의 본 서비스 사용 금지, 또는 거래 검증 증가 등을 포함하되 이에 국한되지 않는다.

		</p>

		<p>

			2. 거래 안전을 보장하기 위해 플랫폼은 귀하가 본 서비스를 사용하는 행위를 모니터링할 권리가 있습니다. 따라서 위험이 비교적 높은 사용자 또는 계정으로 합리적으로 인정되는 경우 플랫폼은 위험 확대를
			방지하고 사용자의 재산과 플랫폼의 생태 안전을 보장하기 위해 필요한 조치를 취할 수 있습니다.이 등 필요한 조치에는 귀하의 계정내의"사탕"을 공제하거나 비우고 귀하의 계정의 전부 또는 일부 기능을 제한하며
			단기간 또는 영구적으로 귀하의 계정을 봉쇄하는 등이 포함된다.

		</p>

		<p>

			3. 플랫폼이 시스템 고장이나 다른 원인으로 인해 발생한 처리 오류를 발견하면 플랫폼에 도움이 되든 귀하에게 도움이 되든 플랫폼은 이 오류를 수정할 권리가 있습니다.이때, 만약 당신이 실제로 받은"사탕"의
			수량이 당신이 획득해야 하는"사탕"보다 적으면 플랫폼은 이 처리 오류를 확인한 후 가능한 한 빨리 차액을 당신의 계정에 보충한다;만약 당신이 실제로 받은"사탕"의 수량이 당신이 획득해야 할"사탕"보다
			많다면 플랫폼은 사전에 통지하지 않고 당신의 계좌번호에서 직접 차액을 공제할 권리가 있다.

		</p>

		<p>4, 플랫폼은 거래 안전, 운영 계획, 국가 법률, 법규 또는 감독 부서의 요구 등 구체적인 상황에 따라 본 서비스를 변경, 중단, 중지 또는 종료할 권리가 있으며, 플랫폼은 이로 인해 귀하에게 어떠한
			권리 옹호 또는 침해 책임을 지지 않습니다.</p>

		﻿

		<h3> 5. 면책 조항</h3>

		<p>

			1. 귀하는 본 서비스가 기존의 기술과 조건에 따라 달성할 수 있는 현황에 따라 제공된다는 것을 이해하고 동의합니다. 플랫폼은 귀하에게 본 서비스를 제공하고 서비스의 일관성과 안전성을 확보하기 위해 최선을
			다할 것입니다.그러나 귀하는 플랫폼이 불가항력, 네트워크상의 이유, 타사 서비스의 결함 등으로 인해 발생할 수 있는 서비스 중단이나 예외 등을 포함하여 기술 및 기타 위험을 언제든지 또는 항상 예견하고
			방지할 수 없다는 것을 알고 인정하며, 이러한 상황이 발생할 경우 플랫폼은 거의 가장 큰 비즈니스 노력을 통해 개선될 수 있지만, 귀하 또는 기타 타사에 대해 어떠한 법적 책임도 질 의무는 없습니다.

		</p>

		<p>2. 플랫폼은 자체적으로 정지 유지 보수, 시스템 업그레이드 및 조정을 할 수 있습니다. 이로 인해 귀하가 본 서비스를 정상적으로 사용할 수 없게 되면 귀하는 플랫폼이 법적 책임을 지지 않아도 된다는
			것에 동의합니다.</p>

		<p>3. 어떠한 상황에서도 플랫폼은 간접성, 징벌성, 우연성 또는 형벌성의 손해배상 책임을 지지 않는다.또한 플랫폼이 귀하에게 부담하는 모든 책임은 어떠한 이유나 방식으로든 귀하가 본 사용으로 지불한 비용을
			초과하지 않습니다.</p>


		<h3> 6. 위약책임</h3>

		<p>1. 만약에 귀하가 본 협의를 위반하면 플랫폼은 법률, 법규, 관련 협의와 플랫폼 관리 규정에 따라 귀하의 행위의 구체적인 상황과 위해 정도에 따라 귀하 또는 귀하의 계정에 대해 관리 조치를 취할 권리가
			있습니다. 경고, 공제 또는 비우기, 귀하의 계정의 전부 또는 일부 기능 제한, 단기 또는 영구적으로 귀하의 계정을 봉쇄하는 것 등을 포함하되 이에 국한되지 않습니다.

		</p>

		<p>

			2. 귀하는 제3자가 귀하의 계정을 이용하여 법규를 위반하거나 본 협의의 약정을 위반한 혐의가 있는 경우 잠재적 피해자 및 기타 사용자의 합법적 권익을 보장하고 플랫폼의 생태질서를 수호하며 손해가 더욱
			확대되는 것을 방지하기 위해 플랫폼도 본 협의와 플랫폼 관련 규칙에 따라 귀하의 계정에 대해 관리 조치를 취할 권리가 있음을 이해하고 인정합니다. 이에 귀하는 플랫폼에 법적 책임을 요구해서는 안 됩니다.

		</p>

		<p>

			3. 만약에 귀하가 플랫폼 밖에서 위법 또는 부당한 행위를 실시하여 플랫폼이 귀하가 본 협의나 귀하가 사용할 수 있는 플랫폼에 대한 다른 규칙(예를 들어 UniLive 사용자 서비스 협의, 커뮤니티 자율
			협약 등)을 위반했거나 위반했다고 판단할 합리적인 이유가 있고 플랫폼은 귀하에게 본 조 제1항에 규정된 관리 조치를 취할 권리가 있으며 이에 대해 귀하는 플랫폼에 법적 책임을 요구해서는 안 됩니다.

		</p>

	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/RechargeAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/RechargeAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/RechargeAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/RechargeAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/RechargeAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/RechargeAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/RechargeAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/RechargeAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/RechargeAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/RechargeAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/RechargeAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/RechargeAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/RechargeAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/RechargeAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>